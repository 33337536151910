import { useMemo } from "react"
import { ListViewProvider } from "./chart/mixed/ListViewProvider"
import { QueryRequestProvider } from "./chart/mixed/QueryRequestProvider"
import { QueryResponseProvider } from "./chart/mixed/QueryResponseProvider"
import {useQueryResponseData} from '../dashboard/chart/mixed/QueryResponseProvider'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

import {
  MixedWidget1,
  MixedTransactions,
  MixedStocks,
  ListsWidget8
} from './chart'
import { useAuth } from "../../modules/auth"
import moment from "moment"
import { Link } from "react-router-dom"




const DashboardWrapper = () => {
  const {currentUser} = useAuth()
  const dashboard = useQueryResponseData();
  const datas = useMemo(() => dashboard, [dashboard])
    return (
      <>
       <div className='row g-5 g-xl-12'>
        <div className='col-xl-4'>
          <MixedWidget1 className='card-xl-stretch mb-xl-4' color='primary' data={datas} />
        </div>
        <div className='col-xl-4'>
        <MixedTransactions className='card-xl-stretch mb-xl-4' color='success' data={datas} />
        </div>
        <div className='col-xl-4'>
        <MixedStocks className='card-xl-stretch mb-xl-4' color='danger' data={datas} />
        </div>
       </div>
       <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <ListsWidget8 className='card-xl-stretch mb-5 mb-xl-8' data={datas} />
        </div>
        <div className="col-xl-6">
        <div className="card border-transparent" data-theme="light" style={{backgroundColor: '#1C325E'}}>
											
												<div className="card-body d-flex ps-xl-15">
												
													<div className="m-0">
													
														<div className="position-relative fs-2x z-index-2 fw-bold text-white mb-7">
														<span className="me-2">Anda mengunakan &nbsp;
														<span className="position-relative d-inline-block text-danger">
															<a href="/subscribe/info" className="text-danger opacity-75-hover">  {currentUser.data.subscription_member.subscription.subscription_description}</a>
														
															<span className="position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100"></span>
															
														</span></span>.
														<br />berlaku s/d {moment(currentUser.data.subscription_member.valid_to).format("DD-MM-YYYY")}</div>
													
														<div className="mb-2">
                              {currentUser.data.subscription_member.subscription.subscription_name !== 'platinum' ? ( <Link to='/subscribe/payment-subscribe' className='btn btn-danger fw-semibold me-2'>
                              Upgrade
                            </Link>
                            
                            ) :(<></>) }
                           <Link to='/subscribe/info' className='btn btn-color-white bg-white bg-opacity-15 bg-hover-opacity-25 fw-semibold'>
                              Info
                            </Link>
                            
														</div>
													
													</div>
													
                          <img
                            src={toAbsoluteUrl('/media/illustrations/sigma-1/17-dark.png')}
                              alt=''
                              className='position-absolute me-3 bottom-0 end-0 h-200px'
                          />
												</div>
		
											</div>
        </div>
      </div>
      
        
        
      </>
    )
  }
  
  const DashboardPage = () => (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <DashboardWrapper />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
  
  export {DashboardPage}