import axios, {AxiosResponse} from 'axios'
import { UserModel } from '../../../../modules/auth/models/UserModel'
import { Dashboard, DashboardQueryResponse } from './_model'

const API_URL = process.env.REACT_APP_API_URL
const REQUEST_DASHBOARD = `${API_URL}/dashboards`

const getDashboards = async (query: string, userData: UserModel): Promise<DashboardQueryResponse> => {
    return await axios.get(`${REQUEST_DASHBOARD}?${query}`,{ params: {
        tenantId: userData.data.user.tenant_id
    }}).then(res => res.data)
  
}

export {getDashboards}



