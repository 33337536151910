const QUERIES = {
  USERS_LIST: 'users-list',

  TRANSACTIONS_LIST_DAILY: 'transactions-list-daily',
  TRANSACTIONS_LIST_MONTHLY: 'transactions-list-monthly',
  TRANSACTIONS_LIST_YEARS: 'transactions-list-years',

  PRODUCTS_LIST : 'products-list',
  PRODUCT_CATEGORIES_LIST : 'product-categories-list',
  STOCKS_LIST : 'stocks-list',

  COAS_LIST : 'coa-list',
  JOURNAL_LIST : 'journal-list',

  UNIT_LABELS: 'unit-labels-list',
  VOUCHER: 'voucher-list'


}

export {QUERIES}
