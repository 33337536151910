/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { useAuth } from '../../../../app/modules/auth'

export function AsideMenuMain() {
  const intl = useIntl()
  const {currentUser} = useAuth()

  if(currentUser.data.user.role.role_name === 'superuser'){
    return (
      <>
       <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
        /> 
        <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Master </span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/users'
        title='Manajemen User'
        icon='/media/icons/duotune/general/gen049.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/members/users' title='User' hasBullet={true} />
        <AsideMenuItem to='/members/roles' title='Role' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/admin/unit-label'
        icon='/media/icons/duotune/text/txt009.svg'
        title='Unit Label'
        fontIcon='bi-app-indicator'
        /> 

      <AsideMenuItemWithSub
        to='/admin/subcribe'
        title='Subscribe'
        icon='/media/icons/duotune/general/gen049.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/admin/subscribes' title='Subscribe' hasBullet={true} />
        <AsideMenuItem to='/admin/subscribe-schema' title='Subscribe Schema' hasBullet={true} />
      </AsideMenuItemWithSub>
        <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Pengaturan</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/admin/voucer-management'
        title='Manajemen Voucher'
        icon='/media/icons/duotune/ecommerce/ecm007.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/admin/voucer-management-list' title='Voucher' hasBullet={true} />
        <AsideMenuItem to='/admin/voucer-management-give' title='Set Voucher' hasBullet={true} />
      </AsideMenuItemWithSub>
      </>
      
    )

  }else{
    return (
      <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      /> 
       <AsideMenuItemWithSub 
        to='/subscribe'
        title='Berlanganan'
        icon='media/icons/duotune/finance/fin002.svg'
        fontIcon='bi-person'
        >
          <AsideMenuItem to='/subscribe/payment-subscribe' title='Beli Langganan & Support' />
          <AsideMenuItem to='/subscribe/info' title='Info Langganan Anda' />
          
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Pengaturan</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/users'
        title='Manajemen User'
        icon='/media/icons/duotune/general/gen049.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/members/users' title='User' hasBullet={true} />
      </AsideMenuItemWithSub>
    
        <AsideMenuItemWithSub
        to='/product'
        title='Manajemen Produk'
        icon='/media/icons/duotune/finance/fin006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/product/productitem' title='Produk' hasBullet={true} />
        <AsideMenuItem to='/product/category' title='Kategori Produk' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/stockproduct'
        icon='/media/icons/duotune/ecommerce/ecm006.svg'
        title='Manajemen Stok'
        fontIcon='bi-app-indicator'
      >
        <AsideMenuItem to='/stockproduct/stock' title='Penyesuaian Stok' hasBullet={true} />
        {/* <AsideMenuItem to='/stockproduct/storagestock' title='Stok Penyimpanan' hasBullet={true} />
        <AsideMenuItem to='/stockproduct/movestock' title='Pemindahan Stok' hasBullet={true} /> */}
      </AsideMenuItemWithSub>
       {/* <AsideMenuItemWithSub
        to='/tenant'
        title='Kelola Toko'
        icon='/media/icons/duotune/ecommerce/ecm004.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/tenant/overview' title='Toko Anda' hasBullet={true} />
        <AsideMenuItem to='/tenant/category' title='Store / Cabang' hasBullet={true} />
      </AsideMenuItemWithSub> */}
       <AsideMenuItemWithSub
        to='/voucher-tenant'
        title='Voucher Customer'
        icon='/media/icons/duotune/finance/fin006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/voucher-tenant/voucher-tenant' title='Voucher Customer' hasBullet={true} />
        <AsideMenuItem to='/voucher-tenant/set-voucher-tenant' title='Set Voucher' hasBullet={true} />
      </AsideMenuItemWithSub>
      
     
  
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Laporan</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Penjualan'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
         <AsideMenuItem to='/reporttransactions/daily' title='Laporan Penjualan Harian' hasBullet={true} />
        <AsideMenuItem to='/reporttransactions/monthly' title='Laporan Penjualan Bulanan' hasBullet={true} />
        <AsideMenuItem to='/reporttransactions/years' title='Laporan Penjualan Tahunan' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Jurnal'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/jurnal/coa' title='Bagan Akun' hasBullet={true} />
        <AsideMenuItem to='/jurnal/journal' title='Jurnal Umum' hasBullet={true} />
        <AsideMenuItem to='/jurnal/report-journal' title='Laporan Jurnal' hasBullet={true} />
      </AsideMenuItemWithSub>
     
      
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
     {/*  <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
    )
  }
  
}
