/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'

const HeaderUserMenu: FC = () => {
  
  const {currentUser, logout} = useAuth()
  let labelSubscribe = <span className='badge badge-light-danger fw-bolder fs-8 px-2 py-1 ms-2'>Free</span>
  if((currentUser?.data.subscription_member && currentUser?.data.subscription_member.subscription.subscription_name) == 'gold') {
    labelSubscribe = <span className='badge badge-light-warning fw-bolder fs-8 px-2 py-1 ms-2'>Gold</span>
  }else if((currentUser?.data.subscription_member && currentUser?.data.subscription_member.subscription.subscription_name) == 'platinum') {
    labelSubscribe = <span className='badge badge-light-info fw-bolder fs-8 px-2 py-1 ms-2'>Platinum</span>
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              
              {currentUser?.data.user.full_name}
            
              {labelSubscribe}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.data.user.tenant.tenant_name}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-3'></div>

      <div className='menu-item px-5'>
        <Link to={'/subscribe/info'} className='menu-link px-5'>
           Info Langganan
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
