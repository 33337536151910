/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserByToken, register,getProvinces, getCities} from '../core/_requests'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import axios from 'axios'
import Swal from 'sweetalert2'


const API_URL = process.env.REACT_APP_API_URL

const initialValues = {
  full_name: '',
  password: '',
  email: '',
  mailing_address: '',
  tenant_name: '',
  changepassword: '',
  acceptTerms: false,
  phoneNumber:'',
  isMarketing: false,
  referrer:'',
  province:'',
  city:'',
  postal_code:'',
  provinceId:0,

}


const registrationSchema = Yup.object().shape({
   full_name: Yup.string()
  .min(3, 'Minimal 3 Karakter')
  .max(50, 'Maksimum 50 Karakter')
  .required('Nama lengkap wajib diisi'),
email: Yup.string()
  .email('Format email salah')
  .min(3, 'Minimal 3 Karakter')
  .max(50, 'Maksimum 50 Karakter')
  .required('Email wajib diisi'),
tenant_name: Yup.string()
  .min(3, 'Minimal 3 Karakter')
  .max(255, 'Maksimum 255 Karakter')
  .required('Nama tenant wajib diisi'),
  mailing_address: Yup.string()
  .min(3, 'Minimal 3 Karakter')
  .max(255, 'Maksimum 255 karakter')
  .required('Alamat wajib diisi'),
password: Yup.string()
  .required('Password wajib diisi')
  /* eslint-disable no-useless-escape */
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\)\(+=._-])(?=.{8,})/,
    "Password harus lebih dari 8 karakter dan terdiri minimal :  1 huruf besar, 2 huruf kecil, 1 Angka dan 1 Karakter spesial "
  ),
phoneNumber: Yup.string()
  .required("Nomor Handphone wajib diisi"),
changepassword: Yup.string()
  .required('Konfirmasi password wajib diisi')
  .when('password', {
    is: (val: string) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf([Yup.ref('password')], "Password and Konformasi Password tidak sama"),
  }),
acceptTerms: Yup.bool().required('Anda harus menyetujui syarat dan ketentuan'),})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const [provinsi, setProvinsi] = useState("")
  const {saveAuth, setCurrentUser} = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await register(
          values.email,
          values.full_name,
          values.password,
          values.mailing_address,
          values.tenant_name,
          values.phoneNumber,
          values.isMarketing,
          values.referrer,
          provinsi,
          values.city,
          values.postal_code
        
        )
            setLoading(false)
            formik.resetForm()
            setStatus("Berhasil membuat akun baru")
            Swal.fire({
              title: "Success",
              text: "Successfully created a new account, please check your email for verification",
              icon: "success",
              confirmButtonText: "OK",
            }).then(function () {
              // Redirect login
              window.location.href = "/auth/login";
            });
            
      } catch (error) {
        saveAuth(undefined)
        setStatus(error.response.data.message)
        setSubmitting(false)
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:  error.response.data.message,
          confirmButtonText: "OK",
        });
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const [provinces, setProvinces] = useState([{}])
  useEffect(() => {
    async function fetchData() {
      try {
        const request = await axios.get(
          `${API_URL}/address/province`
        );
  
        let appData = request.data.data.provinces
        setProvinces(appData);
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);
 
  const [cities, setCities] = useState([{}])

  const [showHidePassword, changeShowHidePassword] = useState(false);
  const [showHidePasswordConfirm, changeShowHidePasswordConfirm] = useState(false);
 // const hasError = form.touched[field.name] && form.errors[field.name];


const SelectChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
  const value = event.target.selectedIndex;
  const prov = event.target.value;
  setProvinsi(prov)
  const {data: kota } = await getCities(value)
  setCities(kota.data.cities)
};

  return (
    <form
    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
    noValidate
    id='kt_login_signup_form'
    onSubmit={formik.handleSubmit}
  >
    {/* begin::Heading */}
    <div className='mb-10 text-center'>
      {/* begin::Title */}
      <h1 className='text-dark mb-3'>Daftar</h1>
      {/* end::Title */}

      {/* begin::Link */}
      <div className='text-gray-400 fw-bold fs-4'>
        Sudah punya akun ?
        <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
          Login
        </Link>
      </div>
      {/* end::Link */}
    </div>
    {/* end::Heading */}

    {formik.status === "Berhasil membuat akun baru" && (
      <div className='mb-lg-15 alert alert-success'>
        <div className='alert-text font-weight-bold'>Berhasil membuat akun, kami telah mengirim kode ve…rifikasi akun untuk dapat masuk kedalam aplikasi</div>
      </div>
    )}

    {formik.status && formik.status !== "Berhasil membuat akun baru" && (
      <div className='mb-lg-15 alert alert-danger'>
        <div className='alert-text font-weight-bold'>{formik.status} </div>
      </div>
    )}
    {/* begin::Form group Firstname */}
    <div className='row fv-row mb-12'>
   
    </div>
    <div className='row fv-row mb-12'>
      <div className='col-xl-12'>
        <label className='class="form-label fw-bolder text-dark fs-6'>Nama Lengkap</label>
        <input
          placeholder='Nama Lengkap'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('full_name')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.full_name && formik.errors.full_name,
            },
            {
              'is-valid': formik.touched.full_name && !formik.errors.full_name,
            }
          )}
        />
        {formik.touched.full_name && formik.errors.full_name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.full_name}</span>
            </div>
          </div>
        )}
      </div>
    </div>
      <div className='col-xl-12'>
        {/* begin::Form group Lastname */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>Nama Tenant</label>
          <input
            placeholder='Nama Tenant'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('tenant_name')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.tenant_name && formik.errors.tenant_name,
              },
              {
                'is-valid': formik.touched.tenant_name && !formik.errors.tenant_name,
              }
            )}
          />
          {formik.touched.tenant_name && formik.errors.tenant_name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.tenant_name}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
      </div>

      <div className='col-xl-12'>
        {/* begin::Form group Lastname */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>Nomor Handphone</label>
          <input
            placeholder='Nomor Handphone'
            type='string'
            autoComplete='off'
            {...formik.getFieldProps('phoneNumber')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber,
              },
              {
                'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
              }
            )}
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.phoneNumber}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
      </div>
    
    {/* end::Form group */}

    {/* begin: form group province */}
    <div className='fv-row mb-7'>
      <label className='form-label fw-bolder text-dark fs-6'>Provinsi</label>
      {/* begin::Input */}

      <select 
    
   name='province'
                className={clsx(
                  'form-select form-select-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.province && formik.errors.province},
                  {
                    'is-valid': formik.touched.province && !formik.errors.province,
                  }
                )}

                onChange={SelectChange}
            >
              <option value=''>- Pilih -</option>
              {provinces.map((province:any) => {
               return (
                 <option key={+province.id} value={province.province}>{province.province}</option>
               )
             })}

            </select>
      
         {formik.touched.province && formik.errors.province && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.province}</span>
                </div>
              </div>
            )} 
            {/* end::Input */}
    </div>
     {/* end::Form group */}

      {/* begin: form group province */}
    <div className='fv-row mb-7'>
      <label className='form-label fw-bolder text-dark fs-6'>Kota / Kabupaten </label>
     
      {/* begin::Input */}
      <select 
      {...formik.getFieldProps('city')}
                className={clsx(
                  'form-select form-select-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.city && formik.errors.city},
                  {
                    'is-valid': formik.touched.city && !formik.errors.city,
                  }
                )}
            >
            
              <option value=''>- Pilih -</option>
              {cities && cities.map((city:any,index) => {
               return (
                 <option key={index} value={city.id}>{city.city}</option>
               )
             })}
             
           

            </select>

      
         {formik.touched.city && formik.errors.city && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.city}</span>
                </div>
              </div>
            )} 
            {/* end::Input */}
    </div>
     {/* end::Form group */}

        {/* begin::Form group Email */}
    <div className='fv-row mb-7'>
      <label className='form-label fw-bolder text-dark fs-6'>Kode Pos</label>
      <input
        placeholder='Kode Pos'
        type='text'
        autoComplete='off'
        {...formik.getFieldProps('postal_code')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
          {'is-invalid': formik.touched.postal_code && formik.errors.postal_code},
          {
            'is-valid': formik.touched.postal_code && !formik.errors.postal_code,
          }
        )}
      />
      {formik.touched.postal_code && formik.errors.postal_code && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors.postal_code}</span>
          </div>
        </div>
      )}
    </div>
    {/* end::Form group */}

    

    {/* begin::Form group Email */}
    <div className='fv-row mb-7'>
      <label className='form-label fw-bolder text-dark fs-6'>Alamat</label>
      <input
        placeholder='Alamat'
        type='text'
        autoComplete='off'
        {...formik.getFieldProps('mailing_address')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
          {'is-invalid': formik.touched.mailing_address && formik.errors.mailing_address},
          {
            'is-valid': formik.touched.mailing_address && !formik.errors.mailing_address,
          }
        )}
      />
      {formik.touched.mailing_address && formik.errors.mailing_address && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors.mailing_address}</span>
          </div>
        </div>
      )}
    </div>
    {/* end::Form group */}

    {/* begin::Form group Email */}
    <div className='fv-row mb-7'>
      <label className='form-label fw-bolder text-dark fs-6'>Email</label>
      <input
        placeholder='Email'
        type='email'
        autoComplete='off'
        {...formik.getFieldProps('email')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
          {'is-invalid': formik.touched.email && formik.errors.email},
          {
            'is-valid': formik.touched.email && !formik.errors.email,
          }
        )}
      />
      {formik.touched.email && formik.errors.email && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        </div>
      )}
    </div>
    {/* end::Form group */}

  {/* begin::Form group Password */}
    <div className='fv-row mb-1'>
      <label className='form-label fw-bolder text-dark fs-6'>Password</label>
    </div>
  
    <div className="input-group mb-4 fv-row" data-kt-password-meter='true'>
    <input
            type={showHidePassword ? "text" : "password"}
            placeholder='Password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
           <span className="input-group-text" id="basic-addon2">
           <i className={showHidePassword ? "fas fa-eye-slash fs-4":"fas fa-eye fs-4"}onClick={() => changeShowHidePassword(!showHidePassword)}></i>
           </span>  
    </div>
    <div className='fv-row mb-1'>
        {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password} </span>
              </div>
            </div>
          )}
      
    </div>

    {/* end::Form group */}

    {/* begin::Form group Confirm password */}
    <div className='fv-row mb-1'>
      <label className='form-label fw-bolder text-dark fs-6'>Konfirmasi Password</label>
    </div>

    <div className="input-group mb-4 fv-row" data-kt-password-meter='true'>
    <input
            type={showHidePasswordConfirm ? "text" : "password"}
            placeholder='Konfirmasi Password'
            autoComplete='off'
            {...formik.getFieldProps('changepassword')}
            className={clsx(
              'form-control',
              {
                'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
              }
            )}
          />
           <span className="input-group-text" id="basic-addon2">
           <i className={showHidePasswordConfirm ? "fas fa-eye-slash fs-4":"fas fa-eye fs-4"}onClick={() => changeShowHidePasswordConfirm(!showHidePasswordConfirm)}></i>
           </span>  
    </div>
    <div className='fv-row mb-1'>
      {formik.touched.changepassword && formik.errors.changepassword && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors.changepassword}</span>
          </div>
        </div>
      )}
    </div>
    {/* end::Form group */}

    

    {/* begin::Form group */}
    <div className='fv-row mb-10'>
      <div className='form-check form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          id='kt_login_toc_agree'
          {...formik.getFieldProps('acceptTerms')}
        />
        <label
          className='form-check-label fw-bold text-gray-700 fs-6'
          htmlFor='kt_login_toc_agree'
        >
          Dengan mendaftar, saya menyatakan menyetujui Ketentuan Layanan dan.
          <a href='https://lolapos.id/kebijakan-privasi/' className='ms-1 link-primary' target="_blank">
            Kebijakan Lola POS
          </a>
          .
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
    </div>
    {/* end::Form group */}


    {/* begin::Form group */}
    <div className='text-center'>
      <button
        type='submit'
        id='kt_sign_up_submit'
        className='btn btn-lg btn-primary w-100 mb-5'
        disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
      >
        {!loading && <span className='indicator-label'>Submit</span>}
        {loading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            Please wait...{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
      <Link to='/auth/login'>
        <button
          type='button'
          id='kt_login_signup_form_cancel_button'
          className='btn btn-lg btn-light-primary w-100 mb-5'
        >
          Cancel
        </button>
      </Link>
    </div>
    {/* end::Form group */}
  </form>
  )

  
}
