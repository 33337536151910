import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL


export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/protected`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/reset-password`
export const FORGOT_PASSWORD_URL = `${API_URL}/auth/reset-password-confirm/`

// Server should return AuthModel
export function login(identity: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    identity,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  full_name: string,
  password: string,
  mailing_address: string,
  tenant_name: string,
  phoneNumber: string,
  isMarketing: boolean, 
  referrer:string,
  province:string,
  city:string,
  postal_code: string,

) {
  return axios.post(REGISTER_URL, {
    email,
    full_name,
    password,
    mailing_address,
    tenant_name,
    phoneNumber,
    isMarketing,
    referrer,
    province,
    city,
    postal_code,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function forgotPasswordConfirm(password:string, userId: string, token:string) {
  return axios.post<{result: boolean}>(FORGOT_PASSWORD_URL+`?userId=${userId}&token=${token}`,{
    password,
  })
}

export function getUserByToken(token: string) {
  /* return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  }) */
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

export function getProvinces() {
  return axios.get(`${API_URL}/address/province`);
}

export function getCities(id_province: number){
  return axios.get(`${API_URL}/address/cities?province_id=${id_province}`)
}
