import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardPage} from '../pages/dashboard/DasboardPage'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {useAuth} from '../../app/modules/auth'

const PrivateRoutes = () => {
  const {currentUser} = useAuth()
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  const ProductsPage = lazy (() => import ('../modules/product/ProductsPage'))
  const ProductCategoryPage = lazy (() => import('../modules/productcategory/ProductCategoryWrapper'))
  const StockPage = lazy (() => import('../modules/stock/Stockpage'))
  const JournalPage = lazy (() => import('../modules/journal/JournalPage'))
  const ReportTransactionsPage = lazy (() => import('../modules/reporttransactions/ReportTransactionsPage'))
  const SubscribePage = lazy (() => import('../modules/subscribe/SubscribePage'))
  const UserPage = lazy (() => import('../modules/users/UserPage'))
  const AdminPage = lazy (() => import('../modules/admin/AdminPage'))
  const VoucherTenant = lazy (() =>import('../modules/vouchertenant/VoucherTenantPage'))

  if(currentUser.data.user.role.role_name === 'superuser'){
    return (
      <Routes>
        <Route element={<MasterLayout/>}>
           {/* Redirect to Dashboard after success login/registartion */}
           <Route path='dashboard' element={<DashboardPage />} />
           <Route path='auth/*' element={<Navigate to='/dashboard' />} />
           <Route
              path='admin/*'
              element={
                <SuspensedView>
                  <AdminPage />
                </SuspensedView>
              }
            />
           <Route path='admin/voucer-management' element={<></>} />
           <Route path='admin/subscribe/*' element={<></>}/>
           <Route path='admin/voucer-management/*' element={<></>} />

           <Route
              path='members/*'
              element={
                <SuspensedView>
                    <UserPage />
                </SuspensedView>
              }
            />
           {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />

        </Route>
      </Routes>
    )
  }else{
    return (
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          {/* Pages */}
          <Route path='dashboard' element={<DashboardPage />} />
          <Route path='builder' element={<BuilderPageWrapper />} />
          <Route path='menu-test' element={<MenuTestPage />} />
          {/* Lazy Modules */}
          <Route path='/category-product' element={<ProductCategoryPage/>} />
          <Route
          path='product/*'
          element={
            <SuspensedView>
              <ProductsPage />
            </SuspensedView>
          }
          />
          <Route
          path='stockproduct/*'
          element={
            <SuspensedView>
              <StockPage />
            </SuspensedView>
          }
        />
         <Route
          path='subscribe/*'
          element={
            <SuspensedView>
              <SubscribePage />
            </SuspensedView>
          }
        />
          <Route
            path='crafted/pages/profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/pages/wizards/*'
            element={
              <SuspensedView>
                <WizardsPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/widgets/*'
            element={
              <SuspensedView>
                <WidgetsPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/account/*'
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/chat/*'
            element={
              <SuspensedView>
                <ChatPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/user-management/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
           <Route
            path='jurnal/*'
            element={
              <SuspensedView>
                <JournalPage />
              </SuspensedView>
            }
          />
            <Route
          path='members/*'
          element={
            <SuspensedView>
              <UserPage />
              
            </SuspensedView>
          }
        />
           <Route 
           path='reporttransactions/*' 
           element={
            <SuspensedView> 
              <ReportTransactionsPage/>
            </SuspensedView>
        
            } />

            <Route 
            path="voucher-tenant/*"
            element={
              <SuspensedView> 
              <VoucherTenant/>
            </SuspensedView>
            } />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    )
  }
 
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
