/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG,toAbsoluteUrl} from '../helpers'
import {Dropdown1} from '../content/dropdown/Dropdown1'

type Props = {
  className: string,
  data:any
}

const ListsWidget8: React.FC<Props> = ({className,data}) => {
  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold text-dark'>Produk Terlaris</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Terdapat { data?.product_favorite.count } produk terlaris</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-3'>
        {/* ---------------------------------------- */ }
          {/* begin data */}
          {data?.product_favorite.count  > 0 ? (
            data?.product_favorite.rows.map((value,index) => {
              return <div key={index} className='d-flex align-items-sm-center mb-7'>
                 <div className='symbol symbol-60px symbol-2by3 me-4'>
                 {value.product_img_url ? (
                 <div
                className='symbol-label'
                style={{backgroundImage: `url(https://api.lolapos.id/${value.product_img_url})`}}
                >
                </div>
                 ) : (<div>-</div>)}

                </div>
                <div className='d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2'>
                  <div className='flex-grow-1 my-lg-0 my-2 me-2'>
                    <a href='#' className='text-gray-800 fw-bold text-hover-primary fs-6'>
                      {value.product_name}
                    </a>
                    <span className='text-muted fw-semibold d-block pt-1'>{(value.product_variant_name)? (value.product_variant_name) : (value.product_description) }</span>
                  </div>
                </div>
                <div className='d-flex align-items-center'>
              <div className='me-6'>
                <i className='fa fa-star me-1 text-warning fs-5'></i>
                <span className='text-gray-800 fw-bold'> {value.quantity} {value.unit_label} terjual</span>
              </div>
            
            </div>
              </div>
            }) 
           ): (
              <div >Tidak ada data</div>
            ) }
        
          {/* end begin data */ }
           {/* ---------------------------------------- */ }

      
      
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget8}
