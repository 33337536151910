
import React, {FC} from 'react'

const SubscribeTitle: FC = () => {
    return (
    <>
    
    </>)
}

export {SubscribeTitle}